var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "error-container" },
    [
      _c("div", { staticClass: "header-gradient" }),
      _c("h1", [_vm._v(_vm._s(_vm.$t("error404.title")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("error404.subtitle")))]),
      _c(
        "b-button",
        { staticClass: "d-bloc mx-auto", attrs: { to: "/", variant: "black" } },
        [_vm._v(_vm._s(_vm.$t("error404.buttonLabel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }